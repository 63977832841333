body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: fit-content(100vh);
  min-height: 100vh;
  background-image: linear-gradient(to bottom right, #213064ff, #1c85adff);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#logo {
  background-image: url("white_text.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 20px;
  width: 161px;
  margin-left: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
}
